<template>
  <div class="app-container">
  <el-row :gutter="4" type="flex" justify="center">
    <el-col :span="20">
      <el-card class="box-card">
        <div slot="header" class="clearfix" justify="center" align="center">
          <strong><span>Opciones de Imagen</span></strong>
        </div>
        <div class="text item">
          <ValidationObserver ref="form" v-slot="{ invalid }">
            <el-form v-on:submit.prevent label-position="top" class="size-form-item" label-width="120px" :model="form">
              <el-row justify="space-around" align="middle">
                <el-col :sm="24" :md="12" :lg="12" align="middle">
                  <div class="gallery-dinamic-inputs">
                    <template v-if="editTitle">
                      <el-row :gutter="10" justify="center" align="middle">
                        <el-col :push="2" :span="18" align="middle">
                          <ValidationProvider name="Titulo" rules="required" v-slot="{ errors }">
                            <el-input class="input-name input-gallery"  v-model="form.title"></el-input>
                            <span style="color: #F56C6C;">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </el-col>
                        <el-col :span="6">
                          <el-tooltip class="item" effect="dark" content="Guardar cambios" placement="right">
                            <el-button @click="handleEditTitle" class="btn-img" icon="el-icon-check" circle plain></el-button>
                          </el-tooltip>
                        </el-col>
                      </el-row>
                    </template>
                    <template v-else-if="!editTitle">
                      <el-row :gutter="10" justify="center" align="middle">
                        <el-col :push="2" :span="18" align="center">
                          <h2>{{ form.title }}</h2>
                        </el-col>
                        <el-col :span="6">
                          <el-tooltip class="item" effect="dark" content="Editar titulo" placement="right">
                            <el-button @click="handleEditTitle" class="btn-img" icon="el-icon-edit" circle plain></el-button>
                          </el-tooltip>
                        </el-col>
                      </el-row>
                    </template>
                  </div>
                  <el-form-item>
                      <el-image style="margin-top: 1em; width: 250px; height: auto;" :src="host + form.path" fit="cover"></el-image>
                  </el-form-item>
                  <div class="gallery-dinamic-inputs">
                    <template v-if="editAlt">
                      <el-row :gutter="10" justify="center" align="middle">
                        <el-col :push="2" :span="18" align="middle">
                          <ValidationProvider name="Titulo" rules="required" v-slot="{ errors }">
                            <el-input type="textarea" class="input-alt input-gallery" v-model="form.alt"
                              :autosize="{ minRows: 1, maxRows: 4}" maxlength="100"
                              show-word-limit></el-input>
                            <span style="color: #F56C6C;">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </el-col>
                        <el-col :span="6">
                          <el-tooltip class="item" effect="dark" content="Guardar cambios" placement="right">
                            <el-button @click="handleEditAlt" class="btn-img" icon="el-icon-check" circle plain></el-button>
                          </el-tooltip>
                        </el-col>
                      </el-row>
                    </template>
                    <template v-else-if="!editAlt">
                      <el-row :gutter="10" justify="center" align="middle">
                        <el-col :push="2" :span="18" align="center">
                          <p style="margin: 0.5em 0 0.5em 0">{{ form.alt }}</p>
                        </el-col>
                        <el-col :span="6">
                          <el-tooltip class="item" effect="dark" content="Editar descripción" placement="right">
                            <el-button @click="handleEditAlt" class="btn-img" icon="el-icon-edit" circle plain></el-button>
                          </el-tooltip>
                        </el-col>
                      </el-row>
                    </template>
                  </div>
                </el-col>
                <el-col :sm="24" :md="12" :lg="12">
                  <el-row justify="center" align="middle">
                    <el-col :span="24">
                      <div class="image-description">
                        <ul>
                          <li>Id:
                            <el-tag size="small" type="warning">{{ form._id }}</el-tag>
                          </li>
                          <li>Titulo de Imagen:
                            <el-tag style="white-space: normal; height: auto;" size="small" type="warning">{{ form.title }}</el-tag>
                          </li>
                          <li>Descripción de Imagen:
                              <el-tag style="white-space: normal; height: auto;" size="small" type="warning">{{ form.alt }}</el-tag>
                          </li>
                          <li>Archivo:
                              <el-tag size="small" type="warning">{{ form.name }}</el-tag>
                          </li>
                          <li>Resolución:
                            <el-tag size="small" type="warning">{{ form.height }}px · {{ form.width }}px</el-tag>
                          </li>
                          <li>Formato:
                            <el-tag size="small" type="warning">{{ form.format }}</el-tag>
                          </li>
                          <li>Calidad:
                            <el-tag size="small" type="warning">{{ form.quality }}%</el-tag>
                          </li>
                          <li>Fecha de Subida:
                            <el-tag size="small" type="warning">{{ form.updated_at | formatDate }}</el-tag>
                          </li>
                          <li>Categorías:
                          </li>
                        </ul>
                        <el-tag :key="tag" v-for="tag in form.metadata.categories"
                              closable
                              :disable-transitions="false"
                              @close="handleClose(tag)">
                              {{ tag }}
                            </el-tag>
                            <el-input class="input-new-tag" v-if="inputVisible"
                              v-model="inputCategory"
                              ref="saveTagInput"
                              size="mini"
                              @keyup.prevent.enter.native="handleInputConfirm"
                              @blur="handleInputConfirm">
                        </el-input>
                        <el-button v-else class="button-new-tag" size="small" @click="showInput">+ Agregar Categoría</el-button>
                      </div>
                    </el-col>
                    <!--<el-col :span="24" align="center">
                      <el-form-item label="Usar como imagen de agradecimiento">
                        <el-switch
                          v-model="form.metadata.randomSelect"
                          active-text="Si"
                          inactive-text="No">
                        </el-switch>
                      </el-form-item>
                    </el-col>-->
                  </el-row>
                </el-col>
              </el-row>
              <el-row type="flex" justify="center">
                <el-col :xs="8" :md="4" align="middle">
                  <el-form-item>
                    <el-button
                      @click.stop.prevent="handlerUpdate"
                      type="primary"
                      icon="el-icon-upload2"
                      :disabled="invalid">
                      Actualizar
                    </el-button>
                  </el-form-item>
                </el-col>
                <el-col :xs="8" :md="4" align="middle">
                  <el-form-item>
                    <el-button
                      @click.prevent="handlerCancel"
                      type="danger">
                      Cancelar
                    </el-button>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </ValidationObserver>
        </div>
      </el-card>
    </el-col>
  </el-row>
  </div>
</template>

<script>
import { getImage } from '@/api/gallery.js'
import { updateImageFile } from '@/api/image.js'
const confElement = document.getElementById('config')
const conf = JSON.parse(confElement.innerHTML)
const urlServer = conf.baseApi
export default {
  name: 'InfoImage',
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      editTitle: false,
      editAlt: false,
      host: urlServer,
      form: {
        metadata: {
          randomSelect: false,
          categories: []
        }
      },
      inputCategory: '',
      inputVisible: false,
      loadingImage: false,
      imageSelected: false
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    handleClose (tag) {
      this.form.metadata.categories.splice(this.form.metadata.categories.indexOf(tag), 1)
    },
    showInput () {
      this.inputVisible = true
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },
    handleInputConfirm (e) {
      e.preventDefault()
      e.stopPropagation()
      const inputValue = this.inputCategory
      if (inputValue) {
        this.form.metadata.categories.push(inputValue)
      }
      this.inputVisible = false
      this.inputCategory = ''
      console.log(this.form.metadata.categories)
    },
    handleEditTitle () {
      console.log(this.editTitle)
      this.editTitle = !this.editTitle
    },
    handleEditAlt () {
      console.log(this.editAlt)
      this.editAlt = !this.editAlt
    },
    async fetchData () {
      try {
        const response = await getImage(this.id)
        if (response.success) {
          console.log(response)
          this.form = await response.data
          console.log(this.form)
        } else {
          console.log(response)
        }
      } catch (err) {
        this.$message.error({
          type: 'info',
          message: 'Ha ocurrido un error al obtener los datos'
        })
      }
    },
    handlerCancel () {
      this.$router.push('/gallery')
    },
    async handlerUpdate (e) {
      e.preventDefault()
      console.log('------------------')
      this.form.metadata = JSON.stringify(this.form.metadata)
      console.log(this.form)
      if (this.form.title !== '' && this.form.alt !== '' && this.form.title !== null && this.form.alt !== null) {
        try {
          const response = await updateImageFile(this.form._id, this.form)
          if (response.success) {
            console.log(response)
            this.$message({
              showClose: true,
              message: '¡Se actualizó correctamente!',
              type: 'success'
            })
            this.$router.push('/gallery')
          } else {
            console.log(response)
          }
        } catch (err) {
          console.log(err)
        }
      } else {
        this.$message.error('Falló')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.image-description{
  padding-top: 1.4em;
  ul{
    list-style: none;
    padding: 0;
    li{
      line-height: 1.6em;
      text-decoration: none;
    }
  }
}

.el-form-item{
    margin-bottom: 0;
}

 .size-form-item > .el-form-item {
  margin: 0;
}
.el-form--label-top .el-form-item__label {
  padding: 0;
  margin-bottom: 0;
}
.my-autocomplete {
  width: 500px;
}
.input-name{
  font-size: 1.6em;
}
.input-alt{
  font-size: 1em;
}

.gallery-dinamic-inputs{
  margin-left: 1.6em;
  h2{
    margin-top: 0.2em;
    margin-bottom: 0.2em;
    line-height: 1em;
    font-size: 1.6em;
    font-weight: normal;
  }
}

.my-autocomplete {
  width: 500px;
}
.button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
}
.input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
}
</style>
